/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { HashLink } from "react-router-hash-link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "swiper/scss";
import "swiper/css/free-mode";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/thumbs";
import SwiperCore, { FreeMode, Navigation, Pagination, Thumbs } from "swiper";

import useThemeObserver from "../hooks/useThemeObserver.js";
import * as Common from "../actions/Common.js";
import Share from "../components/Share.js";
import Toast from "../components/Toast.js";
import QuickMenu from "../components/QuickMenu.js";
import QuickSNS from "../components/QuickSNS.js";
import ModalConfirm from "../components/ModalConfirm.js";
import ModalInsuranceHistory from "../components/ModalInsuranceHistory.js";
import styles from "./Product.module.scss";

import optionThumb1 from "../assets/icons/option-1.svg";
import optionThumb2 from "../assets/icons/option-2.svg";
import optionThumb3 from "../assets/icons/option-3.svg";
import optionThumb4 from "../assets/icons/option-4.svg";
import optionThumb5 from "../assets/icons/option-5.svg";
import optionThumb6 from "../assets/icons/option-6.svg";
import optionThumb7 from "../assets/icons/option-7.svg";
import optionThumb8 from "../assets/icons/option-8.svg";
import optionThumb9 from "../assets/icons/option-9.svg";
import optionThumb10 from "../assets/icons/option-10.svg";
import underIcon from "../assets/icons/under.svg";
import ownerLogoImage from "../assets/images/autoc_logo.png";
import carImg1 from "../assets/images/87302.png";
import cartIcon from "../assets/icons/cart-gr.svg";
import shareWhIcon from "../assets/icons/share-wh.svg";
import heartWhIcon from "../assets/icons/heart-wh.svg";
import exchangeIcon from "../assets/icons/exchange.svg";
import panelIcon from "../assets/icons/panel.svg";
import scratchIcon from "../assets/icons/scratch.svg";
import heartPrIcon from "../assets/icons/pr-full-heart-pr.svg";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const PRODUCT_URL = "/api/user/product/v1";
const CART_URL = "/api/user/product/v1/cart";
const FAVORITE_URL = `/api/user/product/v1/favorite`;
const BUYREQUEST_URL = "/api/user/deal/v1/buy_request";
const CANCEL_URL = "/api/user/deal/v1/cancel";

const Product = () => {
    //* 페이지 state
    const navigate = useNavigate();
    const { id } = useParams();

    //* 로그인 상태
    const [loginStatus, setLoginStatus] = useState(false);
    const [isLoginReady, setIsLoginReady] = useState(false);

    //* API loading state
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);

    /****************************************
     * TODO 초기 세팅
     ****************************************/
    //* Swiper 초기화
    SwiperCore.use([Navigation, Pagination]);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    //* 옵션 정보 아코디언 메뉴
    const [optionState, setOptionState] = useState(false);
    const toggleOptionDetails = () => setOptionState((prev) => !prev);

    //* 차량 데미지 베이스 설정
    const [carDamages, setCarDamages] = useState([]);
    const [carScratch, setCarScratch] = useState([]);
    const [carDamageEle, setCarDamageEle] = useState({
        top: [
            {
                id: 1,
                name: "앞 범퍼",
                position: {
                    top: "-1%",
                    left: "46%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 2,
                name: "앞 휀더(좌)",
                position: {
                    top: "12%",
                    left: "21%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 3,
                name: "앞 휀더(우)",
                position: {
                    top: "12%",
                    left: "72%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 4,
                name: "본넷",
                position: {
                    top: "13%",
                    left: "46%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 5,
                name: "루프패널",
                position: {
                    top: "62%",
                    left: "46%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 6,
                name: "트렁크 도어",
                position: {
                    top: "91%",
                    left: "46%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 7,
                name: "앞 도어(좌)",
                position: {
                    top: "45%",
                    left: "19%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 8,
                name: "뒷 도어(좌)",
                position: {
                    top: "63%",
                    left: "19%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 9,
                name: "앞 도어(우)",
                position: {
                    top: "45%",
                    left: "74%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 10,
                name: "뒷 도어(우)",
                position: {
                    top: "63%",
                    left: "74%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 11,
                name: "뒷 휀더(좌)",
                position: {
                    top: "81%",
                    left: "22%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 12,
                name: "뒷 휀더(우)",
                position: {
                    top: "81%",
                    left: "70%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 13,
                name: "뒷 범퍼",
                position: {
                    top: "98%",
                    left: "46%",
                },
                damaged: false,
                type: null,
            },
        ],
        bottom: [
            {
                id: 41,
                name: "프론트패널",
                position: {
                    top: "5%",
                    left: "46%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 42,
                name: "인사이드 패널(좌)",
                position: {
                    top: "15%",
                    left: "24%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 43,
                name: "인사이드 패널(우)",
                position: {
                    top: "15%",
                    left: "68%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 44,
                name: "휠 하우스(좌, 전면)",
                position: {
                    top: "23%",
                    left: "24%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 45,
                name: "휠 하우스(우, 전면)",
                position: {
                    top: "23%",
                    left: "68%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 46,
                name: "사이드 멤버(좌)",
                position: {
                    top: "18%",
                    left: "34%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 47,
                name: "사이드 멤버(우)",
                position: {
                    top: "18%",
                    left: "58%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 48,
                name: "휠 하우스(좌, 후면)",
                position: {
                    top: "82%",
                    left: "24%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 49,
                name: "휠 하우스(우, 후면)",
                position: {
                    top: "82%",
                    left: "68%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 50,
                name: "리어패널",
                position: {
                    top: "92%",
                    left: "46%",
                },
                damaged: false,
                type: null,
            },
        ],
        side: [
            {
                id: 21,
                name: "전면 라이트(우)",
                position: {
                    top: "3%",
                    left: "24%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 22,
                name: "전면 라이트(좌)",
                position: {
                    top: "3%",
                    left: "69%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 23,
                name: "A필러(우)",
                position: {
                    top: "39%",
                    left: "4%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 24,
                name: "A필러(좌)",
                position: {
                    top: "39%",
                    left: "89%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 25,
                name: "B필러(우)",
                position: {
                    top: "52%",
                    left: "8%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 26,
                name: "B필러(좌)",
                position: {
                    top: "52%",
                    left: "83%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 27,
                name: "C필러(우)",
                position: {
                    top: "67%",
                    left: "2%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 28,
                name: "C필러(좌)",
                position: {
                    top: "67%",
                    left: "91%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 29,
                name: "후면 라이트(우)",
                position: {
                    top: "93%",
                    left: "17%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 30,
                name: "후면 라이트(좌)",
                position: {
                    top: "93%",
                    left: "76%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 31,
                name: "사이드 미러(우)",
                position: {
                    top: "33%",
                    left: "13%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 32,
                name: "사이드 미러(좌)",
                position: {
                    top: "33%",
                    left: "80%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 33,
                name: "사이드실 패널(우)",
                position: {
                    top: "51%",
                    left: "37%",
                },
                damaged: false,
                type: null,
            },
            {
                id: 34,
                name: "사이드실 패널(좌)",
                position: {
                    top: "51%",
                    left: "56%",
                },
                damaged: false,
                type: null,
            },
        ],
    });

    //* 사고 부위와 일치하는 사고 부위 섹션 엘리먼트 설정
    useEffect(() => {
        damageCheck();
    }, [carDamages]);

    const damageCheck = () => {
        carDamages.map((damaged) => {
            //? state 전체 배열에 대한 검색 및 수정
            setCarDamageEle((value) => {
                const target = Object.values(value)
                    .flat()
                    .find(({ id }) => id === damaged.damageNumber);
                target.damaged = true;
                target.type = damaged.damageType;

                return { ...value };
            });

            //? 특정 배열 직접 수정
            // setCarDamageEle((prev) => ({
            //   ...prev,
            //   top: prev.top.map((item) =>
            //     item.id === damaged.damageNumber ? { ...item, damaged: true } : item
            //   ),
            // }));

            //? 배열 데이터 검색
            // carDamageEle.side.find((e) => {
            //   if (e.id === damaged.damageNumber) {
            //     e.damaged = true;
            //     console.log(e.id);
            //   }
            // });
        });

        // 차량 외관 스크래치 체크
        setCarScratch(carDamages.filter((el) => el.damageType === "SCRATCH"));
    };

    //* Init API Data
    const [myData, setMyData] = useState([]);
    const [item, setItem] = useState([]);
    const [isFavorite, setIsFavorite] = useState(false);
    const [isCart, setIsCart] = useState(false);
    const [insuranceHistory, setInsuranceHistory] = useState();
    const [similarItems, setSimilarItems] = useState([]); // 유사 차량
    const [favoriteItems, setFavoriteItems] = useState([]); // 찜한 차량
    const [cartItems, setCartItems] = useState([]); // 장바구니 차량

    const resetDatas = () => {
        setItem([]);
        setIsFavorite(false);
        setIsCart(false);
        setInsuranceHistory();
        setSimilarItems([]);
        setFavoriteItems([]);
        setCartItems([]);
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const fetchData = async () => {
        try {
            //* 상세 데이터 세팅
            const response = await fetchDetailData();
            //! response.error.status === 500일 경우, dialog 출력 및 뒤로가기 버튼 구현
            if (response === undefined) {
                return;
            }

            //* 보험이력 세팅
            await fetchHistoryData();
            //* 유사차량 세팅
            await fetchSimilarData();

            //* 토큰 상태 확인
            const result = await fetchMyData();

            if (!result) {
                setLoginStatus(false);
            } else {
                setLoginStatus(true);

                //* 찜한 차량 세팅 (로그인 상태)
                await fetchFavoriteData(response.data.data.id);
                //* 장바구니 세팅 (로그인 상태)
                await fetchCartData(response.data.data.id);
                // 유사차량
                await fetchCartDataSimilar();
                await fetchFavoriteDataSimilar();
            }

            setLoaded(true);
        } catch (error) {}
    };

    //* 스크롤 이벤트, 화면 좌측 내비게이션 값 세팅
    const [scrollY, setScrollY] = useState(0);
    const [infoNavState, setInfoNavState] = useState(false);

    const handleScroll = () => {
        setScrollY(window.pageYOffset);
        if (scrollY > 200) {
            setInfoNavState(true);
        } else if (scrollY == 0) {
            setInfoNavState(false);
        } else {
            setInfoNavState(false);
        }
    };

    useEffect(() => {
        const scrollListener = () => {
            window.addEventListener("scroll", handleScroll);
        };
        scrollListener();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    const [navNumber, setNavNumber] = useState(1);
    const [carImageRef] = useThemeObserver(setNavNumber, 1);
    const [carDetailRef] = useThemeObserver(setNavNumber, 2);
    const [carOptionRef] = useThemeObserver(setNavNumber, 3);
    const [carintroRef] = useThemeObserver(setNavNumber, 4);
    const [carDiagInfoRef] = useThemeObserver(setNavNumber, 5);
    const [carAccidentRef] = useThemeObserver(setNavNumber, 6);
    const [carDiagSumRef] = useThemeObserver(setNavNumber, 7);
    const [carInsuranceRef] = useThemeObserver(setNavNumber, 8);
    const [recommendRef] = useThemeObserver(setNavNumber, 9);

    const navScrollTo = (targetId) => {
        const element = document.getElementById(targetId);
        const offset = 40;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        });

        // const el = document.getElementById("carInsuranceRef");
        // el.scrollIntoView({
        //   behavior: "smooth",
        //   block: "start",
        // });
    };

    // 인터섹션 옵저버 : LNB 포커싱 처리
    const $cards = document.querySelectorAll("section");
    if ($cards !== undefined) {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    let id = entries[0].target.id;
                    // let clientRect = entries[0].boundingClientRect;
                    // let intersectionRect = entries[0].intersectionRect;
                    // let rootBounds = entries[0].rootBounds;
                    // console.log(
                    //   id +
                    //     ": " +
                    //     clientRect.height +
                    //     "/" +
                    //     intersectionRect.height +
                    //     "/" +
                    //     rootBounds.height
                    // );

                    if (id === "carImageRef") {
                        setNavNumber(1);
                    } else if (id === "carDetailRef") {
                        setNavNumber(2);
                    } else if (id === "carOptionRef") {
                        setNavNumber(3);
                    } else if (id === "carintroRef") {
                        setNavNumber(4);
                    } else if (id === "carDiagInfoRef") {
                        setNavNumber(5);
                    } else if (id === "carAccidentRef") {
                        setNavNumber(6);
                    } else if (id === "carDiagSumRef") {
                        setNavNumber(7);
                    } else if (id === "carInsuranceRef") {
                        setNavNumber(8);
                    } else if (id === "recommendRef") {
                        setNavNumber(9);
                    }
                }
                // entries.forEach((entry) => {
                //   entry.target.classList.toggle("visible", entry.isIntersecting);
                //   console.log(entry.target);
                // });
                // threshold: 0.5 옵션을 전달해, 요소가 50% 이상 보일 때 콜백을 호출합니다.
                // threshold의 범위는 0 ~ 1 (0% ~ 100%) 입니다.
            },
            { rootMargin: "-140px 0px -75% 0px", threshold: 0.22 }
            // { rootMargin: "-20px 0px -75% 0px", threshold: 0.3 }
        );

        $cards.forEach((card) => {
            observer.observe(card);
        });
    }

    // 스크롤 오프셋 설정
    const scrollWithOffset = (el) => {
        setTimeout(() => {
            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -143;
            window.scrollTo({ top: yCoordinate + yOffset /*, behavior: "smooth" */ });
        }, 5);
    };

    //* (로그아웃 상태) 상품 데이터 cookie에 쌓기
    const setMyCookies = (item) => {
        if (loginStatus) {
            return;
        } else {
            let cookieList = [];
            const newCookie = {
                viewedAt: Common.getCurrentTime(),
                carId: item.id,
            };

            if (Common.getCookieJson("chada_recent_view") != null) {
                cookieList = Common.getCookieJson("chada_recent_view");
            }

            // 쿠키에 같은 상품 ID값이 있는지 확인
            let checkCookieId = true;
            if (cookieList && cookieList.length != 0) {
                let removeTargetIdx = null;

                cookieList.find((val, idx) => {
                    if (val.carId === item.id) {
                        removeTargetIdx = idx;
                        // checkCookieId = true;
                    }
                });
                if (removeTargetIdx !== null) {
                    cookieList.splice(removeTargetIdx, 1);
                }
            }

            // 쿠키 ID 중복값이 없는 경우 쿠키 새로 저장
            if (checkCookieId === true) {
                // 쿠키 length 20개 이상인 경우 마지막 요소 제거
                if (cookieList.length >= 20) {
                    cookieList.shift();
                }
                cookieList.unshift(newCookie);
                Common.setCookie("chada_recent_view", JSON.stringify(cookieList), 1);
            }
        }
    };

    /****************************************
     * TODO 항목별 API 목록
     ****************************************/
    // 토큰 상태 확인
    const fetchMyData = async () => {
        try {
            const response = await axios.get(BASE_URL + MYDATA_URL, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });

            setMyData(response.data.data);
            setIsLoginReady(true);
            return response;
        } catch (err) {
            // console.error(err.response?.data.message);
            return false;
        }
    };
    // 상품 상세
    const fetchDetailData = async () => {
        setLoading(true);

        let params = `/${id}`;
        if (isLoginReady === true && loginStatus === false) {
            params = `/${id}`;
        } else if (isLoginReady === true && loginStatus === true) {
            params = `/${id}?memberId=${myData.id}`; // 로그인 상태에서는 memberId도 같이 전달(서버에서 최근 본 차량에 포함시키기 위해)
        }
        try {
            const response = await axios.get(BASE_URL + PRODUCT_URL + params);

            setItem(response.data.data); // 아이템 상세 정보
            setCarDamages(response.data.data.carDamages); // 차량 데미지
            setMyCookies(response.data.data); // 상품 쿠키에 저장

            if (response?.status === 200) {
                setLoading(false);
            }
            return response;
        } catch (error) {
            if (error.response?.data.result === 500) {
                setLoading(true);
                setModal({
                    open: true,
                    dim: true,
                    title: error.response?.data.message,
                    message: "",
                    notxt: "",
                    oktxt: "확인",
                    callback2: () => {
                        navigate(-1);
                    },
                });
            } else {
                handleToast(error.response?.data.message);
                setLoading(false);
            }
        }
    };
    // 찜한 차량
    const fetchFavoriteData = async (id) => {
        try {
            const response = await axios.get(BASE_URL + FAVORITE_URL, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });

            response.data.data.some((el) => el.id === id) ? setIsFavorite(true) : setIsFavorite(false);
        } catch (error) {
            console.log(error);
        }
    };
    // 찜하기 설정 / 찜하기 해제
    const fetchFavoriteSet = async () => {
        try {
            if (isFavorite === false) {
                //? favorite 추가
                const response = await axios.post(BASE_URL + FAVORITE_URL, [id], {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                setIsFavorite(true);
                handleToast("찜한차량으로 등록 되었습니다.");
            } else if (isFavorite === true) {
                //? favorite 해제
                const response = await axios.delete(BASE_URL + FAVORITE_URL, {
                    data: [id],
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                setIsFavorite(false);
                handleToast("찜한차량이 등록 해제 되었습니다.");
            }
        } catch (err) {
            console.log(err.response?.status);
            if (err.response?.status === 401) {
                handleToast("세션이 만료되었습니다.");
                return;
            } else {
                handleToast(err.response?.data.message);
            }
        }
    };
    // 장바구니 세팅
    const fetchCartData = async (id) => {
        try {
            const response = await axios.get(BASE_URL + CART_URL, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });

            // setIsCart(true);
            response.data.data.some((el) => el.id === id) ? setIsCart(true) : setIsCart(false);
        } catch (error) {
            console.log(error);
        }
    };
    // 장바구니 담기
    const fetchCartAdd = async () => {
        try {
            if (isCart === false) {
                //? cart 추가
                const response = await axios.post(BASE_URL + CART_URL, [id], {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                setIsCart(true);
                handleToast("장바구니에 등록 되었습니다.");
            } else if (isCart === true) {
                //? cart 해제
                const response = await axios.delete(BASE_URL + CART_URL, {
                    data: [id],
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                setIsCart(false);
                handleToast("장바구니에서 등록 해제 되었습니다.");
            }
        } catch (err) {
            handleToast(err.response?.data.message);
        }
    };
    // 구매 요청 진행
    const fetchBuyRequest = async () => {
        try {
            const response = await axios.post(
                BASE_URL + BUYREQUEST_URL,
                { carIds: [id] },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                }
            );

            return response;
        } catch (err) {
            handleToast(err.response?.data.message);
        }
    };
    // 구매 요청 취소
    const fetchCancelRequest = async () => {
        try {
            const response = await axios.post(
                BASE_URL + CANCEL_URL,
                { carIds: [id] },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                }
            );

            handleToast(response.data.message);
            navigate("/home");
        } catch (err) {
            handleToast(err.response?.data.message);
        }
    };
    // 보험 이력
    const fetchHistoryData = async () => {
        try {
            const response = await axios.get(BASE_URL + PRODUCT_URL + `/${id}/car_history`);

            setInsuranceHistory(response.data.data);
        } catch (err) {
            return false;
        }
    };
    // 유사 차량
    const fetchSimilarData = async () => {
        try {
            const response = await axios.get(BASE_URL + PRODUCT_URL + `/${id}/similar_car`);

            setSimilarItems(response.data.data);
        } catch (error) {
            handleToast(error.response?.data.message);
        }
    };
    // 유사차량 찜하기
    const fetchFavoriteSetSimilar = async (target, id) => {
        try {
            if (target === "off") {
                //? favorite 추가
                const response = await axios.post(BASE_URL + FAVORITE_URL, [id], {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });

                handleToast("찜한차량으로 등록 되었습니다.");
            } else if (target === "on") {
                //? favorite 해제
                const response = await axios.delete(BASE_URL + FAVORITE_URL, {
                    data: [id],
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });

                handleToast("찜한차량이 등록 해제 되었습니다.");
            }

            fetchFavoriteDataSimilar();
            // fetchSimilarData();
        } catch (err) {
            console.log(err);
        }
    };
    // 유사차량 장바구니 담기
    const fetchCartAddSimilar = async (target, id) => {
        try {
            if (target === "off") {
                //? cart 추가
                const response = await axios.post(BASE_URL + CART_URL, [id], {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });

                handleToast("장바구니에 등록 되었습니다.");
            } else if (target === "on") {
                //? cart 해제
                const response = await axios.delete(BASE_URL + CART_URL, {
                    data: [id],
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });

                handleToast("장바구니에서 등록 해제 되었습니다.");
            }

            fetchCartDataSimilar();
        } catch (err) {
            handleToast(err.response?.data.message);
        }
    };
    // 장바구니 차량
    const fetchCartDataSimilar = async () => {
        try {
            const response = await axios.get(BASE_URL + CART_URL, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
            setCartItems(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };
    // 찜한 차량
    const fetchFavoriteDataSimilar = async () => {
        try {
            const response = await axios.get(BASE_URL + FAVORITE_URL, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
            setFavoriteItems(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    /****************************************
     * TODO 이벤트 핸들러
     ****************************************/
    const handleShare = async (carId, carData) => {
        setShareModal({
            open: true,
            dim: true,
            carId: carId,
            carData: carData,
        });
    };

    //* 찜하기 버튼 클릭
    const handleFavorite = async () => {
        if (!loginStatus) {
            setModal({
                open: true,
                dim: true,
                title: "로그인이 필요합니다.",
                message: "지금 로그인 하시겠습니까?",
                notxt: "취소",
                oktxt: "로그인 하기",
                callback2: () => {
                    navigate("/login");
                },
            });
            return;
        }

        fetchFavoriteSet();
    };
    // 장바구니 담기
    const handleCart = async () => {
        if (!loginStatus) {
            setModal({
                open: true,
                dim: true,
                title: "로그인이 필요합니다.",
                message: "지금 로그인 하시겠습니까?",
                notxt: "취소",
                oktxt: "로그인 하기",
                callback2: () => {
                    navigate("/login");
                },
            });
            return;
        }

        fetchCartAdd();
    };
    // 구매요청
    const onRequestBuy = () => {
        // 로그인 여부 확인
        if (!loginStatus) {
            setModal({
                open: true,
                dim: true,
                title: "로그인이 필요합니다.",
                message: "지금 로그인 하시겠습니까?",
                notxt: "취소",
                oktxt: "로그인 하기",
                callback2: () => {
                    navigate("/login");
                },
            });
            return;
        }

        //TODO 가입 인증 확인

        //TODO

        // 구매 요청 진행
        setModal({
            open: true,
            dim: true,
            title: "구매 요청을 진행하시겠습니까?",
            message:
                "※ 구매진행 시 먼저 결제(입금)가  완료된 회원에게 최종 구매 진행권이 주어집니다. 최종 구매 불가 시 입금하신 금액은 전액 환불 처리 됩니다.",
            notxt: "취소",
            oktxt: "확인",
            callback2: async () => {
                const result = await fetchBuyRequest();

                if (result === null || result === undefined) {
                    return;
                }

                setModal({
                    open: true,
                    dim: true,
                    title: "구매 요청을 완료했습니다.",
                    message: "구매 진행 페이지로 이동하시겠습니까?",
                    notxt: "아니오",
                    oktxt: "예",
                    callback1: () => {
                        navigate("/buy");
                    },
                    callback2: () => {
                        navigate("/mypage", {
                            state: {
                                tabIndex: 1,
                            },
                        });
                    },
                });
            },
        });
    };
    // 구매요청 취소
    const onRequestCancel = () => {
        setModal({
            open: true,
            dim: true,
            title: "구매 요청을 취소하시겠습니까?",
            message: "",
            notxt: "취소",
            oktxt: "확인",
            callback2: () => {
                fetchCancelRequest();
            },
        });
    };
    // 부가서비스 선택
    const onRequestService = () => {
        setModal({
            open: true,
            dim: true,
            title: "부가서비스를 신청하시겠습니까?",
            message: "",
            notxt: "그냥 구매할게요.",
            oktxt: "신청하기",
            callback1: () => {
                //TODO 7.1.1 이동(구매 진행 페이지)
                navigate("/checkout", { state: { carIds: [id] } });
                // navigate("/checkout", {
                //   state: {
                //     carIds: [id],
                //     addServices: null,
                //     deliveryLocation: null,
                //     addLocationYn: "N",
                //   },
                // });
            },
            callback2: () => {
                //TODO 6.1.1 이동(부가서비스 페이지)
                navigate("/service", { state: { carIds: [id] } });
            },
        });
    };
    // 유사차량 찜하기
    const handleFavoriteSimilar = async (e, id) => {
        e.preventDefault();

        if (!loginStatus) {
            setModal({
                open: true,
                dim: true,
                title: "로그인이 필요합니다.",
                message: "지금 로그인 하시겠습니까?",
                notxt: "취소",
                oktxt: "로그인 하기",
                callback2: () => {
                    navigate("/login");
                },
            });
            return;
        }

        const isFavorite = e.currentTarget.dataset.favorite;
        fetchFavoriteSetSimilar(isFavorite, id);
    };
    // 유사차량 장바구니
    const handleCartSimilar = async (e, id) => {
        e.preventDefault();

        if (!loginStatus) {
            setModal({
                open: true,
                dim: true,
                title: "로그인이 필요합니다.",
                message: "지금 로그인 하시겠습니까?",
                notxt: "취소",
                oktxt: "로그인 하기",
                callback2: () => {
                    navigate("/login");
                },
            });
            return;
        }

        const isCart = e.currentTarget.dataset.cart;
        fetchCartAddSimilar(isCart, id);
    };
    /****************************************
     * TODO 토스트 & 모달 상태
     ****************************************/
    //* 토스트 팝업 세팅
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

    const handleToast = (txt) => {
        setToastStatus(true);
        setToastMsg(txt);
    };
    useEffect(() => {
        if (toastStatus) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, 1000);
        }
    }, [toastStatus]);

    //* 공유 모달
    const [shareModal, setShareModal] = useState({
        open: false,
        dim: true,
        carId: id,
        carData: null,
    });

    //* 모달
    const [modal, setModal] = useState({
        open: false,
        dim: true,
        title: "",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback1: false,
        callback2: false,
    });

    // 보험 이력 모달
    const [modalInsHistory, setModalInsHistory] = useState({
        open: false,
        dim: true,
        callback: false,
        carId: id,
    });

    return (
        <>
            {loading ? (
                <div className="loader"></div>
            ) : (
                <div className={`${styles.product}` + " global-width"}>
                    {/* 화면 좌측 차량 정보 */}
                    <div className={styles.info}>
                        {/* 차량 정보 내비게이션 */}
                        <nav className={styles.infoNav}>
                            {infoNavState ? (
                                <ul>
                                    <li className={navNumber <= 2 && navNumber > 0 ? styles.active : null}>
                                        <span onClick={() => navScrollTo("carDetailRef")}>차량정보</span>
                                        {/* <HashLink
                      to="#carDetailRef"
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      차량정보
                    </HashLink> */}
                                    </li>
                                    <li className={navNumber <= 3 && navNumber > 2 ? styles.active : null}>
                                        <span onClick={() => navScrollTo("carOptionRef")}>옵션정보</span>
                                        {/* <HashLink
                      to="#carOptionRef"
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      옵션정보
                    </HashLink> */}
                                    </li>
                                    <li className={navNumber <= 4 && navNumber > 3 ? styles.active : null}>
                                        <span onClick={() => navScrollTo("carintroRef")}>차량소개</span>
                                        {/* <HashLink
                      to="#carintroRef"
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      차량소개
                    </HashLink> */}
                                    </li>
                                    {/* <li className={navNumber <= 5 && navNumber > 4 ? styles.active : null}>
                                        <span onClick={() => navScrollTo("carDiagInfoRef")}>진단정보</span> */}
                                    {/* <HashLink
                      to="#carDiagInfoRef"
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      진단정보
                    </HashLink> */}
                                    {/* </li> */}
                                    <li className={navNumber <= 7 && navNumber > 5 ? styles.active : null}>
                                        <span onClick={() => navScrollTo("carAccidentRef")}>사고부위</span>
                                        {/* <HashLink
                      to="#carAccidentRef"
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      사고부위
                    </HashLink> */}
                                    </li>
                                    <li className={navNumber >= 8 ? styles.active : null}>
                                        <span onClick={() => navScrollTo("carInsuranceRef")}>보험이력</span>
                                        {/* <HashLink
                      to="#carInsuranceRef"
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      보험이력
                    </HashLink> */}
                                    </li>
                                </ul>
                            ) : null}
                        </nav>

                        {/* 이미지 */}
                        <section className={styles.carImage} ref={carImageRef} id="carImageRef">
                            {loaded && item.carImages && item.carImages ? (
                                <>
                                    <Swiper
                                        className={`${styles.mainImage}` + " swiperThumbMain"}
                                        loop={false}
                                        style={{
                                            "--swiper-navigation-color": "#fff",
                                            "--swiper-pagination-color": "#fff",
                                        }}
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        navigation={true}
                                        thumbs={{ swiper: thumbsSwiper }}
                                        modules={[Pagination, FreeMode, Thumbs]}
                                        pagination={{
                                            type: "fraction",
                                        }}
                                    >
                                        {item.carImages &&
                                            item.carImages.map((img) => {
                                                return (
                                                    <SwiperSlide key={img.id}>
                                                        <img src={img.url} />
                                                    </SwiperSlide>
                                                );
                                            })}
                                    </Swiper>
                                    {loading === true ? null : (
                                        <Swiper
                                            className={`${styles.thumbImage}` + " swiperThumb"}
                                            onSwiper={setThumbsSwiper}
                                            loop={false}
                                            spaceBetween={0}
                                            slidesPerView={8}
                                            freeMode={true}
                                            watchSlidesProgress={true}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                        >
                                            {item.carImages &&
                                                item.carImages.map((img) => {
                                                    return (
                                                        <SwiperSlide key={img.id}>
                                                            <img src={img.url} />
                                                        </SwiperSlide>
                                                    );
                                                })}
                                        </Swiper>
                                    )}
                                </>
                            ) : null}
                        </section>
                        {/* 차량정보 */}
                        <section className={styles.carDetail} ref={carDetailRef} id="carDetailRef">
                            <div className={styles.secTit}>
                                <h3 className="h3 fwb">차량 정보</h3>
                            </div>
                            <div className={styles.secInner}>
                                <div className={styles.listBox}>
                                    <div className={styles.itemList}>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>차종</div>
                                            <div className={styles.itemDesc}>
                                                {item.carType == "COMPACT"
                                                    ? "경차"
                                                    : item.carType == "SMALL"
                                                    ? "소형"
                                                    : item.carType == "SMALL_MID"
                                                    ? "준중형"
                                                    : item.carType == "MID"
                                                    ? "중형"
                                                    : item.carType == "BIG"
                                                    ? "대형"
                                                    : item.carType == "RV"
                                                    ? "RV"
                                                    : item.carType == "SUV"
                                                    ? "SUV"
                                                    : item.carType == "VAN"
                                                    ? "VAN"
                                                    : item.carType == "ETC"
                                                    ? "기타"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>연식/형식년도</div>
                                            <div className={styles.itemDesc}>
                                                {item.carMadedYear + "." + item.carMadedMonth + " / " + item.carMadedYear + "년형"}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>주행거리</div>
                                            <div className={styles.itemDesc}>{Common.commaFormat(item.mileage)}km</div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>최초 등록일</div>
                                            <div className={styles.itemDesc}>
                                                {item.carHistory?.initRegDate
                                                    ? item.carHistory?.initRegDate.slice(0, 4) +
                                                      "." +
                                                      item.carHistory?.initRegDate.slice(4, 6) +
                                                      "." +
                                                      item.carHistory?.initRegDate.slice(6, 8)
                                                    : "-"}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>경과개월</div>
                                            <div className={styles.itemDesc}>
                                                {item.carHistory?.initRegDate
                                                    ? Common.getElapsedMonths(item.carHistory?.initRegDate) + "개월"
                                                    : "-"}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>색상</div>
                                            <div className={styles.itemDesc}>{item.color}</div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>변속기</div>
                                            <div className={styles.itemDesc}>
                                                {item.motorType == "MANUAL"
                                                    ? "수동"
                                                    : item.motorType == "AUTOMATIC"
                                                    ? "자동"
                                                    : item.motorType == "CVT"
                                                    ? "CVT"
                                                    : item.motorType == "DUAL"
                                                    ? "듀얼클러치"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>연료</div>
                                            <div className={styles.itemDesc}>
                                                {item.fuelType == "GAS"
                                                    ? "가솔린"
                                                    : item.fuelType == "DIESEL"
                                                    ? "디젤"
                                                    : item.fuelType == "LPG"
                                                    ? "LPG"
                                                    : item.fuelType == "GAS_LPG"
                                                    ? "가솔린(LPG겸용)"
                                                    : item.fuelType == "GAS_CNG"
                                                    ? "가솔린(CNG겸용)"
                                                    : item.fuelType == "GAS_HYBRID"
                                                    ? "HEV(하이브리드)"
                                                    : item.fuelType == "ELECTRIC"
                                                    ? "전기"
                                                    : item.fuelType == "HYDROGEN"
                                                    ? "수소"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>배기량</div>
                                            <div className={styles.itemDesc}>{Common.commaFormat(item.displacement)}cc</div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>차량번호</div>
                                            <div className={styles.itemDesc}>{item.carNumber}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* 옵션 정보 */}
                        <section className={styles.carOption} ref={carOptionRef} id="carOptionRef">
                            <div className={styles.secTit}>
                                <h3 className="h3 fwb">옵션 정보</h3>
                            </div>
                            <div className={styles.secInner}>
                                <div className={styles.optionThumbs}>
                                    {item.majorOptions?.map((opt) => {
                                        return (
                                            opt.option === "SUN_ROOF" && (
                                                <div
                                                    key={opt.id}
                                                    className={opt.checked === true ? `${styles.itemActive}` : `${styles.item}`}
                                                >
                                                    <div className={styles.itemTit}>
                                                        <img src={optionThumb1} alt="" />
                                                    </div>
                                                    <div className={styles.itemDesc}>썬루프</div>
                                                </div>
                                            )
                                        );
                                    })}
                                    {item.majorOptions?.map((opt) => {
                                        return (
                                            opt.option === "SMARTKEY" && (
                                                <div
                                                    key={opt.id}
                                                    className={opt.checked === true ? `${styles.itemActive}` : `${styles.item}`}
                                                >
                                                    <div className={styles.itemTit}>
                                                        <img src={optionThumb2} alt="" />
                                                    </div>
                                                    <div className={styles.itemDesc}>스마트키</div>
                                                </div>
                                            )
                                        );
                                    })}
                                    {item.majorOptions?.map((opt) => {
                                        return (
                                            opt.option === "NAVIGATION" && (
                                                <div
                                                    key={opt.id}
                                                    className={opt.checked === true ? `${styles.itemActive}` : `${styles.item}`}
                                                >
                                                    <div className={styles.itemTit}>
                                                        <img src={optionThumb3} alt="" />
                                                    </div>
                                                    <div className={styles.itemDesc}>내비게이션</div>
                                                </div>
                                            )
                                        );
                                    })}
                                    {item.majorOptions?.map((opt) => {
                                        return (
                                            opt.option === "REAR_CAMERA" && (
                                                <div
                                                    key={opt.id}
                                                    className={opt.checked === true ? `${styles.itemActive}` : `${styles.item}`}
                                                >
                                                    <div className={styles.itemTit}>
                                                        <img src={optionThumb4} alt="" />
                                                    </div>
                                                    <div className={styles.itemDesc}>후방카메라</div>
                                                </div>
                                            )
                                        );
                                    })}
                                    {item.majorOptions?.map((opt) => {
                                        return (
                                            opt.option === "WARM_SHEET_FRONT" && (
                                                <div
                                                    key={opt.id}
                                                    className={opt.checked === true ? `${styles.itemActive}` : `${styles.item}`}
                                                >
                                                    <div className={styles.itemTit}>
                                                        <img src={optionThumb5} alt="" />
                                                    </div>
                                                    <div className={styles.itemDesc}>열선시트</div>
                                                </div>
                                            )
                                        );
                                    })}
                                    {item.majorOptions?.map((opt) => {
                                        return (
                                            opt.option === "CRUISE_CONTROLE_ACTIVE" && (
                                                <div
                                                    key={opt.id}
                                                    className={opt.checked === true ? `${styles.itemActive}` : `${styles.item}`}
                                                >
                                                    <div className={styles.itemTit}>
                                                        <img src={optionThumb8} alt="" />
                                                    </div>
                                                    <div className={styles.itemDesc}>스마트 크루즈 컨트롤</div>
                                                </div>
                                            )
                                        );
                                    })}
                                    {item.majorOptions?.map((opt) => {
                                        return (
                                            opt.option === "LDWS" && (
                                                <div
                                                    key={opt.id}
                                                    className={opt.checked === true ? `${styles.itemActive}` : `${styles.item}`}
                                                >
                                                    <div className={styles.itemTit}>
                                                        <img src={optionThumb7} alt="" />
                                                    </div>
                                                    <div className={styles.itemDesc}>차량이탈 경보시스템</div>
                                                </div>
                                            )
                                        );
                                    })}
                                    {item.majorOptions?.map((opt) => {
                                        return (
                                            opt.option === "REAR_NOTI" && (
                                                <div
                                                    key={opt.id}
                                                    className={opt.checked === true ? `${styles.itemActive}` : `${styles.item}`}
                                                >
                                                    <div className={styles.itemTit}>
                                                        <img src={optionThumb6} alt="" />
                                                    </div>
                                                    <div className={styles.itemDesc}>후측방 경보시스템</div>
                                                </div>
                                            )
                                        );
                                    })}
                                    {item.majorOptions?.map((opt) => {
                                        return (
                                            opt.option === "PARKING_SENSOR_FRONT" && (
                                                <div
                                                    key={opt.id}
                                                    className={opt.checked === true ? `${styles.itemActive}` : `${styles.item}`}
                                                >
                                                    <div className={styles.itemTit}>
                                                        <img src={optionThumb9} alt="" />
                                                    </div>
                                                    <div className={styles.itemDesc}>전방감지기</div>
                                                </div>
                                            )
                                        );
                                    })}
                                    {item.majorOptions?.map((opt) => {
                                        return (
                                            opt.option === "PARKING_SENSOR_REAR" && (
                                                <div
                                                    key={opt.id}
                                                    className={opt.checked === true ? `${styles.itemActive}` : `${styles.item}`}
                                                >
                                                    <div className={styles.itemTit}>
                                                        <img src={optionThumb10} alt="" />
                                                    </div>
                                                    <div className={styles.itemDesc}>후방감지기</div>
                                                </div>
                                            )
                                        );
                                    })}
                                </div>
                                <div className={optionState ? `${styles.optionDetails} ${styles.active}` : `${styles.optionDetails}`}>
                                    <div className={styles.detailItem}>
                                        <div className={styles.itemCont}>
                                            <div className={styles.itemHeader}>
                                                <span className={styles.itemTit}>외관</span>
                                                <span className={styles.itemCnt}>
                                                    {loaded &&
                                                        item.outSideOptions &&
                                                        item.outSideOptions.filter((item) => item.checked === true).length}
                                                </span>
                                            </div>
                                            <div className={styles.itemList}>
                                                {loaded &&
                                                    item.outSideOptions &&
                                                    item.outSideOptions.map((opt) => {
                                                        return (
                                                            opt.checked && (
                                                                <span className={styles.item} key={opt.id}>
                                                                    {opt.nameKr}
                                                                </span>
                                                            )
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                        <div className={styles.itemCont}>
                                            <div className={styles.itemHeader}>
                                                <span className={styles.itemTit}>내장</span>
                                                <span className={styles.itemCnt}>
                                                    {loaded &&
                                                        item.inSideOptions &&
                                                        item.inSideOptions.filter((item) => item.checked === true).length}
                                                </span>
                                            </div>
                                            <div className={styles.itemList}>
                                                {loaded &&
                                                    item.inSideOptions &&
                                                    item.inSideOptions.map((opt) => {
                                                        return (
                                                            opt.checked && (
                                                                <span className={styles.item} key={opt.id}>
                                                                    {opt.nameKr}
                                                                </span>
                                                            )
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                        <div className={styles.itemCont}>
                                            <div className={styles.itemHeader}>
                                                <span className={styles.itemTit}>안전</span>
                                                <span className={styles.itemCnt}>
                                                    {loaded &&
                                                        item.safetyOptions &&
                                                        item.safetyOptions.filter((item) => item.checked === true).length}
                                                </span>
                                            </div>
                                            <div className={styles.itemList}>
                                                {loaded &&
                                                    item.safetyOptions &&
                                                    item.safetyOptions.map((opt) => {
                                                        return (
                                                            opt.checked && (
                                                                <span className={styles.item} key={opt.id}>
                                                                    {opt.nameKr}
                                                                </span>
                                                            )
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                        <div className={styles.itemCont}>
                                            <div className={styles.itemHeader}>
                                                <span className={styles.itemTit}>편의</span>
                                                <span className={styles.itemCnt}>
                                                    {loaded &&
                                                        item.comfortOptions &&
                                                        item.comfortOptions.filter((item) => item.checked === true).length}
                                                </span>
                                            </div>
                                            <div className={styles.itemList}>
                                                {loaded &&
                                                    item.comfortOptions &&
                                                    item.comfortOptions.map((opt) => {
                                                        return (
                                                            opt.checked && (
                                                                <span className={styles.item} key={opt.id}>
                                                                    {opt.nameKr}
                                                                </span>
                                                            )
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.moreDetails}>
                                        <div className={styles.buttons} onClick={toggleOptionDetails}>
                                            <span>옵션 자세히 보기 ({loaded && item.carOptions && item.carOptions.length}개)</span>
                                            <img src={underIcon} alt="" />{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* 차량 소개 */}
                        <section className={styles.carIntro} ref={carintroRef} id="carintroRef">
                            <div className={styles.secTit}>
                                <h3 className="h3 fwb">차량 소개</h3>
                            </div>
                            <div className={styles.secInner}>
                                <p className={styles.introNote}>{item.description}</p>
                            </div>
                        </section>
                        {/* 진단 정보 */}
                        <section className={styles.carDiagInfo} ref={carDiagInfoRef} id="carDiagInfoRef" style={{ display: "none" }}>
                            <div className={styles.secTit}>
                                <h3 className="h3 fwb">진단 정보</h3>
                            </div>
                            <div className={styles.secInner}>
                                <div className={styles.listBox}>
                                    <div className={styles.itemList}>
                                        {/* <div className={styles.item}>
                    <div className={styles.itemTit}>사고여부</div>
                    <div className={styles.itemDesc}>
                      {item.accident == "NONE"
                        ? "무사고"
                        : item.accident == "SIMPLE_CHANGE"
                        ? "단순교환"
                        : item.accident == "SIMPLE_TOUCH"
                        ? "단순사고(접촉)"
                        : item.accident == "NORMAL"
                        ? "사고"
                        : item.accident == "TOTAL_LOSS"
                        ? "전손"
                        : item.accident == "FLOODING"
                        ? "침수"
                        : null}
                    </div>
                  </div> */}
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>시트 상태</div>
                                            <div className={styles.itemDesc}>
                                                {item.seatStatus == "GOOD"
                                                    ? "양호"
                                                    : item.seatStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.seatStatus == "NON_CHECK"
                                                    ? "미확인"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>내장재 상태</div>
                                            <div className={styles.itemDesc}>
                                                {item.intMatStatus == "GOOD"
                                                    ? "양호"
                                                    : item.intMatStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>실내 냄새</div>
                                            <div className={styles.itemDesc}>
                                                {item.insideScentStatus == "GOOD"
                                                    ? "양호"
                                                    : item.insideScentStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.insideScentStatus == "NON_CHECK"
                                                    ? "미확인"
                                                    : null}
                                            </div>
                                        </div>
                                        {/* <div className={styles.item}>
                    <div className={styles.itemTit}>타이어 상태</div>
                    <div className={styles.itemDesc}>
                      {loaded &&
                      [
                        item.frontLeftTireStatus,
                        item.frontRightTireStatus,
                        item.rearLeftTireStatus,
                        item.rearRightTireStatus,
                      ].some((status) => status === "GOOD")
                        ? "양호"
                        : status === "NEED_CHECK"
                        ? "점검필요"
                        : "불량(교체필요)"}
                    </div>
                  </div> */}
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>오일 누유</div>
                                            <div className={styles.itemDesc}>
                                                {item.oilLeakStatus == "GOOD"
                                                    ? "양호"
                                                    : item.oilLeakStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.oilLeakStatus == "BAD"
                                                    ? "불량(교체필요)"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>공조</div>
                                            <div className={styles.itemDesc}>
                                                {item.conditioningStatus == "GOOD"
                                                    ? "양호"
                                                    : item.conditioningStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>엔진</div>
                                            <div className={styles.itemDesc}>
                                                {item.engineStatus == "GOOD"
                                                    ? "양호"
                                                    : item.engineStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.engineStatus == "MISAE"
                                                    ? "미세비침"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>미션</div>
                                            <div className={styles.itemDesc}>
                                                {item.missionStatus == "GOOD"
                                                    ? "양호"
                                                    : item.missionStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.missionStatus == "BAD"
                                                    ? "불량(교체필요)"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>제동부분</div>
                                            <div className={styles.itemDesc}>
                                                {item.breakStatus == "GOOD"
                                                    ? "양호"
                                                    : item.breakStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.breakStatus == "BAD"
                                                    ? "불량(교체필요)"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>전기부분</div>
                                            <div className={styles.itemDesc}>
                                                {item.electricStatus == "GOOD"
                                                    ? "양호"
                                                    : item.electricStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.electricStatus == "BAD"
                                                    ? "불량(교체필요)"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>운전석 앞 타이어</div>
                                            <div className={styles.itemDesc}>
                                                {item.frontLeftTireStatus == "GOOD"
                                                    ? "양호"
                                                    : item.frontLeftTireStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.frontLeftTireStatus == "BAD"
                                                    ? "불량(교체필요)"
                                                    : item.frontLeftTireStatus == "NON_CHECK"
                                                    ? "미확인"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>운전석 뒤 타이어</div>
                                            <div className={styles.itemDesc}>
                                                {item.rearLeftTireStatus == "GOOD"
                                                    ? "양호"
                                                    : item.rearLeftTireStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.rearLeftTireStatus == "BAD"
                                                    ? "불량(교체필요)"
                                                    : item.rearLeftTireStatus == "NON_CHECK"
                                                    ? "미확인"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>조수석 앞 타이어</div>
                                            <div className={styles.itemDesc}>
                                                {item.frontRightTireStatus == "GOOD"
                                                    ? "양호"
                                                    : item.frontRightTireStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.frontRightTireStatus == "BAD"
                                                    ? "불량(교체필요)"
                                                    : item.frontRightTireStatus == "NON_CHECK"
                                                    ? "미확인"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>조수석 뒤 타이어</div>
                                            <div className={styles.itemDesc}>
                                                {item.rearRightTireStatus == "GOOD"
                                                    ? "양호"
                                                    : item.rearRightTireStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.rearRightTireStatus == "BAD"
                                                    ? "불량(교체필요)"
                                                    : item.rearRightTireStatus == "NON_CHECK"
                                                    ? "미확인"
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* 사고 부위 확인 */}
                        <section className={styles.carAccident} ref={carAccidentRef} id="carAccidentRef">
                            <div className={styles.secTit}>
                                <h3 className="h3 fwb">사고 부위 확인</h3>
                            </div>
                            <div className={styles.secInner}>
                                <div className={styles.accidentParts}>
                                    <span className={styles.partItem}>
                                        <img src={panelIcon} alt="" />
                                        판금
                                    </span>
                                    <span className={styles.partItem}>
                                        <img src={exchangeIcon} alt="" />
                                        교환
                                    </span>
                                    <span className={styles.partItem}>
                                        <img src={scratchIcon} alt="" />
                                        흠집
                                    </span>
                                </div>
                                <div className={styles.accidentDetail}>
                                    {item?.inspectImgUrl ? (
                                        <div className={styles.detailCarInspectionUrl}>
                                            <img src={item.inspectImgUrl} alt="" />
                                        </div>
                                    ) : (
                                        <div className={styles.detailCar}>
                                            <div className={styles.imgBox} data-img-index="img-01">
                                                <div className={styles.elList}></div>
                                            </div>
                                            <div className={styles.imgBox} data-img-index="img-02">
                                                <div className={styles.elList}></div>
                                            </div>
                                            <div className={styles.imgBox} data-img-index="img-03">
                                                <div className={styles.elList}></div>
                                            </div>
                                        </div>
                                    )}
                                    {/* <div className={styles.detailCar}>
                                        <div className={styles.imgBox} data-img-index="img-01">
                                            <div className={styles.elList}>
                                                {carDamageEle.top.map((el) => {
                                                    return (
                                                        el.damaged && (
                                                            <span className={styles.el} style={el.position} key={el.id} data-type={el.type}>
                                                                <span className={styles.elName}>{el.name}</span>
                                                            </span>
                                                        )
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className={styles.imgBox} data-img-index="img-02">
                                            <div className={styles.elList}>
                                                {carDamageEle.bottom.map((el) => {
                                                    return (
                                                        el.damaged && (
                                                            <span className={styles.el} style={el.position} key={el.id} data-type={el.type}>
                                                                <span className={styles.elName}>{el.name}</span>
                                                            </span>
                                                        )
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className={styles.imgBox} data-img-index="img-03">
                                            <div className={styles.elList}>
                                                {carDamageEle.side.map((el) => {
                                                    return (
                                                        el.damaged && (
                                                            <span className={styles.el} style={el.position} key={el.id} data-type={el.type}>
                                                                <span className={styles.elName}>{el.name}</span>
                                                            </span>
                                                        )
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                        {/* 진단 요약 */}
                        <section className={styles.carDiagSum} ref={carDiagSumRef} id="carDiagSumRef" style={{ display: "none" }}>
                            <div className={styles.secTit}>
                                <h3 className="h3 fwb">진단 요약</h3>
                            </div>
                            <div className={styles.secInner}>
                                <div className={styles.listBox}>
                                    <div className={styles.itemList}>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>키세트</div>
                                            <div className={styles.itemDesc}>
                                                {item.keySet == "EA_1"
                                                    ? "1개"
                                                    : item.keySet == "EA_2"
                                                    ? "2개"
                                                    : item.keySet == "OTHER"
                                                    ? "기타(DP키 추가)"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>썬팅</div>
                                            <div className={styles.itemDesc}>
                                                {item.sunTing == "GOOD"
                                                    ? "양호"
                                                    : item.sunTing == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.sunTing == "BAD"
                                                    ? "불량(교체필요)"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>블랙박스</div>
                                            <div className={styles.itemDesc}>
                                                {item.blackBox == "NONE"
                                                    ? "미장착"
                                                    : item.blackBox == "GOOD"
                                                    ? "장착(양호)"
                                                    : item.blackBox == "NEED_CHECK"
                                                    ? "장착(점검필요)"
                                                    : item.blackBox == "BAD"
                                                    ? "불량(교체필요)"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>경정비</div>
                                            <div className={styles.itemDesc}>
                                                {item.organization == "GOOD"
                                                    ? "양호"
                                                    : item.organization == "NEED_CHECK"
                                                    ? "단순점검필요"
                                                    : item.organization == "ALL_NEED_CHECK"
                                                    ? "전체점검필요"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>외관</div>
                                            <div className={styles.itemDesc}>
                                                {carScratch.length > 0 ? carScratch.length + "판수리필요" : "양호"}
                                                {/* 00판수리필요 */}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>실내</div>
                                            <div className={styles.itemDesc}>
                                                {item.insideStatus == "GOOD"
                                                    ? "양호"
                                                    : item.insideStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.insideStatus == "BAD"
                                                    ? "불량(교체필요)"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>냄새케어</div>
                                            <div className={styles.itemDesc}>
                                                {item.scentStatus == "GOOD"
                                                    ? "양호"
                                                    : item.scentStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.scentStatus == "NON_CHECK"
                                                    ? "미확인"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>엔진룸</div>
                                            <div className={styles.itemDesc}>
                                                {item.engineRoomStatus == "GOOD"
                                                    ? "양호"
                                                    : item.engineRoomStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>광택</div>
                                            <div className={styles.itemDesc}>
                                                {item.glossyStatus == "GOOD"
                                                    ? "양호"
                                                    : item.glossyStatus == "NEED_CHECK"
                                                    ? "광택필요"
                                                    : null}
                                            </div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>배터리</div>
                                            <div className={styles.itemDesc}>
                                                {item.batteryStatus == "GOOD"
                                                    ? "양호"
                                                    : item.batteryStatus == "NEED_CHECK"
                                                    ? "점검필요"
                                                    : item.batteryStatus == "BAD"
                                                    ? "교체필요"
                                                    : null}
                                            </div>
                                        </div>
                                        {/* <div className={styles.item}>
                    <div className={styles.itemTit}>타이어</div>
                    <div className={styles.itemDesc}>
                      {loaded &&
                      [
                        item.frontLeftTireStatus,
                        item.frontRightTireStatus,
                        item.rearLeftTireStatus,
                        item.rearRightTireStatus,
                      ].some((status) => status === "GOOD")
                        ? "양호"
                        : status === "NEED_CHECK"
                        ? "점검필요"
                        : "불량(교체필요)"}
                    </div>
                  </div> */}
                                    </div>
                                </div>

                                <p className={styles.diagNote}>{item.checkNote}</p>
                            </div>
                        </section>
                        {/* 보험이력 */}
                        <section className={styles.carInsurance} ref={carInsuranceRef} id="carInsuranceRef">
                            <div className={styles.secTit}>
                                <h3 className="h3 fwb">보험이력</h3>
                                <p className={styles.secNotice}>*아래의 보험이력은 보험개발원이 제공하는 정보입니다</p>
                            </div>
                            <div className={styles.secInner}>
                                <div className={styles.listBox}>
                                    <div className={styles.itemList}>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>내차 피해</div>
                                            <div className={styles.itemDesc}>{insuranceHistory?.selfDamagedAccidentCount}건</div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>상대차 피해</div>
                                            <div className={styles.itemDesc}>{insuranceHistory?.toDamagedAccidentCount}건</div>
                                        </div>
                                        <div className={styles.item}>
                                            <div className={styles.itemTit}>특수사고이력</div>
                                            <div className={styles.itemDesc}>
                                                {insuranceHistory?.normalAccidentCountTotal +
                                                    insuranceHistory?.stolenAccidentCountTotal +
                                                    insuranceHistory?.floodingAccidentCountTotal}
                                                건
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.moreDetails}>
                                        <div
                                            className={styles.buttons}
                                            onClick={() => {
                                                document.body.style.overflow = "hidden";
                                                setModalInsHistory({
                                                    open: true,
                                                    dim: true,
                                                    callback: () => {
                                                        handleToast("데이터를 불러올 수 없습니다.");
                                                    },
                                                    carId: id,
                                                });
                                            }}
                                        >
                                            <span>보험이력 자세히보기</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* 유사차량 추천 */}
                        {similarItems.length != 0 ? (
                            <section className={styles.recommend} ref={recommendRef} id="recommendRef">
                                <div className={styles.secTit}>
                                    <h3 className="h3 fwb">유사차량 추천</h3>
                                </div>
                                <div className={styles.secInner}>
                                    <Swiper
                                        className={styles.itemList}
                                        spaceBetween={24}
                                        slidesPerView={2}
                                        loop={true}
                                        navigation
                                        pagination={{
                                            clickable: true,
                                        }}
                                    >
                                        {similarItems.map((item, idx) => {
                                            return (
                                                <SwiperSlide key={idx}>
                                                    <div className={styles.item}>
                                                        <Link to={`/product/${item.id}`} className={styles.img} target="_blank">
                                                            <img src={item.mainImgUrl} alt="" />
                                                            <div className={styles.category}>
                                                                {item.newYn === "Y" && <span className={styles.new}>New</span>}
                                                                {item.productYn === "Y" && <span className={styles.commerce}>상품화</span>}
                                                                {item.productStatus === "BUY_ING" && (
                                                                    <span className={styles.purchasing}>구매 진행 중</span>
                                                                )}
                                                            </div>
                                                            <div className={styles.service}>
                                                                <button
                                                                    className={styles.share}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleShare(item.id, item);
                                                                    }}
                                                                >
                                                                    <img src={shareWhIcon} alt="" />
                                                                </button>
                                                                {favoriteItems.some((el) => el.id === item.id) ? (
                                                                    <button
                                                                        type="button"
                                                                        className={styles.favorite}
                                                                        onClick={(e) => {
                                                                            handleFavoriteSimilar(e, item.id);
                                                                        }}
                                                                        data-favorite="on"
                                                                    >
                                                                        <img src={heartPrIcon} alt="" />
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        type="button"
                                                                        className={styles.favorite}
                                                                        onClick={(e) => {
                                                                            handleFavoriteSimilar(e, item.id);
                                                                        }}
                                                                        data-favorite="off"
                                                                    >
                                                                        <img src={heartWhIcon} alt="" />
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </Link>
                                                        <div className={styles.txt}>
                                                            <div className={styles.tit}>
                                                                <span>
                                                                    <Link to={`/product/${item.id}`} target="_blank">
                                                                        {item.manufacturerName +
                                                                            " " +
                                                                            item.modelName +
                                                                            " (" +
                                                                            item.carNumber +
                                                                            ")"}
                                                                    </Link>
                                                                </span>
                                                            </div>
                                                            <div className={styles.sub}>
                                                                {item.productedAt && (
                                                                    <span>
                                                                        {String(item.carMadedYear).slice(2) +
                                                                            "." +
                                                                            String(item.carMadedMonth) +
                                                                            " (" +
                                                                            String(item.carMadedYear).slice(2) +
                                                                            "년형)"}
                                                                    </span>
                                                                )}
                                                                {item.mileage && (
                                                                    <span>
                                                                        {Common.commaFormat(item.mileage)}
                                                                        km
                                                                    </span>
                                                                )}
                                                                {item.fuelType && (
                                                                    <span>
                                                                        {item.fuelType == "GAS"
                                                                            ? "가솔린"
                                                                            : item.fuelType == "DIESEL"
                                                                            ? "디젤"
                                                                            : item.fuelType == "LPG"
                                                                            ? "LPG"
                                                                            : item.fuelType == "GAS_LPG"
                                                                            ? "가솔린(LPG겸용)"
                                                                            : item.fuelType == "GAS_CNG"
                                                                            ? "가솔린(CNG겸용)"
                                                                            : item.fuelType == "GAS_HYBRID"
                                                                            ? "HEV(하이브리드)"
                                                                            : item.fuelType == "ELECTRIC"
                                                                            ? "전기"
                                                                            : item.fuelType == "HYDROGEN"
                                                                            ? "수소"
                                                                            : null}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className={styles.desc}>
                                                                {item.carOptions &&
                                                                    item.carOptions.map((opt, idx) => {
                                                                        return <span key={idx}>{opt}</span>;
                                                                    })}
                                                            </div>
                                                            <div className={styles.summary}>
                                                                <span className={styles.price}>
                                                                    {" "}
                                                                    {Common.commaFormat(String(item.carPrice.price).slice(0, -4))}
                                                                    만원
                                                                </span>
                                                                {cartItems.some((el) => el.id === item.id) ? (
                                                                    <button
                                                                        type="button"
                                                                        className={`${styles.optionItem} ${styles.cart}`}
                                                                        onClick={(e) => {
                                                                            handleCartSimilar(e, item.id);
                                                                        }}
                                                                        data-cart="on"
                                                                    ></button>
                                                                ) : (
                                                                    <button
                                                                        type="button"
                                                                        className={`${styles.optionItem} ${styles.cart}`}
                                                                        onClick={(e) => {
                                                                            handleCartSimilar(e, item.id);
                                                                        }}
                                                                        data-cart="off"
                                                                    ></button>
                                                                )}
                                                                {/* <span>
                                <img src={cartIcon} alt="" />
                              </span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                </div>
                            </section>
                        ) : null}
                        {/*  */}
                    </div>

                    {/* 화면 우측 차량 정보 요약 */}
                    <div className={styles.summary}>
                        <div className={styles.header}>
                            <h3>
                                {item.manufacturerName + " " + item.modelName} ({item.carNumber})
                            </h3>
                            <div className={styles.sumDetail}>
                                <span>
                                    {String(item.carMadedYear).slice(2) +
                                        "." +
                                        String(item.carMadedMonth) +
                                        " (" +
                                        String(item.carMadedYear).slice(2) +
                                        "년형)"}
                                </span>
                                <span>{Common.commaFormat(item.mileage)}km</span>
                                <span>
                                    {item.fuelType == "GAS"
                                        ? "가솔린"
                                        : item.fuelType == "DIESEL"
                                        ? "디젤"
                                        : item.fuelType == "LPG"
                                        ? "LPG"
                                        : item.fuelType == "GAS_LPG"
                                        ? "가솔린(LPG겸용)"
                                        : item.fuelType == "GAS_CNG"
                                        ? "가솔린(CNG겸용)"
                                        : item.fuelType == "GAS_HYBRID"
                                        ? "HEV(하이브리드)"
                                        : item.fuelType == "ELECTRIC"
                                        ? "전기"
                                        : item.fuelType == "HYDROGEN"
                                        ? "수소"
                                        : null}
                                </span>
                            </div>
                            <div className={styles.sumOption}>
                                {item.majorOptions?.map((opt) => {
                                    return (
                                        opt.checked && (
                                            <span className={styles.item} key={opt.id}>
                                                {opt.nameKr}
                                            </span>
                                        )
                                    );
                                    // return (
                                    //   opt.checked &&
                                    //   (opt.option === "SUN_ROOF" ||
                                    //   opt.option === "WARM_SHEET_FRONT" ||
                                    //   opt.option === "LDWS" ||
                                    //   opt.option === "REAR_NOTI" ||
                                    //   opt.option === "PARKING_SENSOR_FRONT" ? (
                                    //     <span className={styles.item} key={opt.id}>
                                    //       {opt.nameKr}
                                    //     </span>
                                    //   ) : null)
                                    // );
                                })}
                            </div>
                            <div className={styles.sumOwner}>
                                <span className={styles.name}>{loaded && item.seller && item.seller.corpName}</span>
                                {/* <span className={styles.logo}>
                  <img src={ownerLogoImage} alt="" />
                </span> */}
                            </div>
                            <div className={styles.sharing}>
                                <button
                                    type="button"
                                    className={`${styles.sharingItem} ${styles.favorite}`}
                                    onClick={handleFavorite}
                                    data-favorite={isFavorite ? "on" : "off"}
                                ></button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleShare(id, item);
                                    }}
                                    className={`${styles.sharingItem} ${styles.share}`}
                                ></button>
                            </div>
                        </div>
                        {loginStatus === true ? (
                            <>
                                <div className={styles.sumAddress}>
                                    <p className={styles.addressDetail}>
                                        {loaded && item.seller && item.seller.corpAddress}&nbsp;
                                        {loaded && item.seller && item.seller.corpAddressDetail}
                                    </p>
                                    <p className={styles.addressNotice}>
                                        {/* *해당 차고지 주소 기준으로 출발지가 설정되고, 입력하는 주소지를
                도착지 기준으로 탁송비가 산정될 예정 입니다. */}
                                        *이 차량은 <em>{item.seller.corpAddress + " " + item.seller.corpAddressDetail}</em>
                                        에 있습니다. <br />
                                        탁송 신청 시 참고 하시기 바랍니다.
                                    </p>
                                </div>
                                <div className={styles.priceInfo}>
                                    <div className={styles.infoItem}>
                                        <span className={styles.name}>차량가</span>
                                        <span className={styles.price}>
                                            {loaded && item.carPrice && Common.commaFormat(item.carPrice.price)}원
                                        </span>
                                    </div>
                                    <div className={styles.infoItem}>
                                        <span className={styles.name}>수수료</span>
                                        <span className={styles.price}>
                                            {loaded && item.carPrice && Common.commaFormat(item.carPrice.comission)}원
                                        </span>
                                    </div>
                                    <div className={styles.infoItem}>
                                        <span className={styles.name}>총 구매 비용 (VAT 포함)</span>
                                        <span className={`${styles.price} ${styles.priceTotal}`}>
                                            {loaded && item.carPrice && Common.commaFormat(item.carPrice.price + item.carPrice.comission)}원
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.loginBox}>
                                    <p>금액 및 계약 진행은 로그인 후 가능 합니다.</p>
                                    <div className="button-form">
                                        <button
                                            type="button"
                                            className="btn btn-xl btn--primary"
                                            onClick={() => {
                                                navigate("/login");
                                            }}
                                        >
                                            로그인
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className={styles.sumNotice}>
                            <h6>유의사항</h6>
                            <p className={styles.desc}>{item.note}</p>
                        </div>
                        <div className={styles.buttons}>
                            {item.productStatus === "EXPOSED" ? (
                                <div className={`${styles.btnItem} ${styles.btnStatus1}` + " button-form"}>
                                    <button
                                        type="button"
                                        className={`btn btn-cart ` + `${styles.cart}`}
                                        onClick={handleCart}
                                        data-cart={isCart ? "on" : "off"}
                                    ></button>
                                    <button type="button" className="btn btn-xl btn--primary" onClick={onRequestBuy}>
                                        구매 요청
                                    </button>
                                </div>
                            ) : item.productStatus === "BUY_REQUEST" ? (
                                <div className={`${styles.btnItem} ${styles.btnStatus2}` + " button-form"}>
                                    <button type="button" className="btn btn-xl btn--secondary" onClick={onRequestCancel}>
                                        구매 요청 취소
                                    </button>
                                </div>
                            ) : item.productStatus === "BUY_CONFIRM" ? (
                                <div className={`${styles.btnItem} ${styles.btnStatus3}` + " button-form"}>
                                    <button type="button" className="btn btn-xl btn--secondary" onClick={onRequestCancel}>
                                        구매 요청 취소
                                    </button>
                                    <button type="button" className="btn btn-xl btn--primary" onClick={onRequestService}>
                                        구매 진행
                                    </button>
                                </div>
                            ) : item.productStatus === "BUY_ING" ? (
                                <div className={`${styles.btnItem} ${styles.btnStatus5}` + " button-form"}>
                                    <button type="button" className="btn btn-xl btn--disabled">
                                        구매 진행 중
                                    </button>
                                </div>
                            ) : (
                                item.productStatus === "FINISH" && (
                                    <div className={`${styles.btnItem} ${styles.btnStatus4}` + " button-form"}>
                                        <button type="button" className="btn btn-xl btn--disabled">
                                            판매 완료
                                        </button>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* 토스트 팝업 */}
            <Toast visible={toastStatus} msg={toastMsg} />

            {/* 공유하기 */}
            {shareModal.open && (
                <Share dim={shareModal.dim} setModal={setShareModal} carId={shareModal.carId} carData={shareModal.carData} />
            )}

            {/* 퀵메뉴 */}
            <QuickMenu />

            {/* SNS - 카카오 */}
            <QuickSNS />

            {/* 로그인 모달 */}
            {modal.open && (
                <ModalConfirm
                    dim={modal.dim}
                    setModal={setModal}
                    title={modal.title}
                    message={modal.message}
                    notxt={modal.notxt}
                    oktxt={modal.oktxt}
                    callback1={modal.callback1}
                    callback2={modal.callback2}
                />
            )}

            {/* 보험 이력 모달 */}
            {modalInsHistory.open && (
                <ModalInsuranceHistory
                    dim={modalInsHistory.dim}
                    setModal={setModalInsHistory}
                    callback={modalInsHistory.callback}
                    carId={id}
                />
            )}
        </>
    );
};

export default Product;
